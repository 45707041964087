import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CommonData } from '../models/commonData';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  http = inject(HttpClient)
  private API: string = environment.BASE_PATH;
  private loginEvent: Subject<boolean> = new Subject<boolean>();
  private logoutEvent: Subject<boolean> = new Subject<boolean>();
  private jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(
  ) { }

  setSession(data: any) {
    if (data.success) {
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      this.loginEvent.next(true);
    }
  }

  clearSession() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.logoutEvent.next(true);
  }

  getUser() {
    const userString = localStorage.getItem('user') as string;
    return userString;
  }

  getToken() {
    return localStorage.getItem('token');
  }

  logout(): void {
    this.clearSession();
  }

  getExamType(page?: number, search?: string): Observable<CommonData> {
    let url = `${this.API}/exam-type?exam_category=B&exam_category=C`;
    if (page) {
      url += `&page=${page}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return this.http.get<CommonData>(url);

  }

  getGrade(exam_type_category: string, search?: string): Observable<CommonData> {
    let url = `${this.API}/grade`;
    if (exam_type_category) {
      url += `?exam_type_category=${exam_type_category}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return this.http.get<CommonData>(url);
  }

  getSubjectOnExamType(
    examTypeIds?: any,
    gradeId = null
  ): Observable<CommonData> {
    let url = `${this.API}/subject/by-tags?`;
    if (examTypeIds) {
      url += `exam_type=${examTypeIds}`;
    }
    if (gradeId) {
      url += `&grade=${gradeId}`;
    }
    return this.http.get<CommonData>(url);
  }

  /**
  *
  * @param examTypeId when have exam type id
  * @param subjectId when have subject id
  * @param gradeId when have grade id
  * @returns the list based on the exam type id, subject id and grade id
  */
  getTopicsOnSubject(
    examTypeId: any,
    subjectId?: any,
    gradeId?: number
  ): Observable<CommonData> {
    let url = `${this.API}/topic/by-subject?`;
    if (examTypeId) {
      url += `exam_type=${examTypeId}`;
    }
    if (subjectId) {
      url += `&subject_id=${subjectId}`;
    }
    if (gradeId) {
      url += `&grade=${gradeId}`;
    }
    return this.http.get<CommonData>(url);
  }

  /**
  *
  * @returns the list for the tag mapping
  */
  getQuestionList(page: number, search?: string, QuestionType?: string, visibility?: string,
    examType?: any, grade?: any, subject?: any, topic?: any
  ): Observable<CommonData> {
    let url = `${this.API}/dashboard-v2/questions?page=${page}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (QuestionType) {
      url += `&question_type=${QuestionType}`;
    }
    if (visibility) {
      url += `&visibility=${visibility}`;
    }
    if (examType?.length) {
      url += `&exam_type=${JSON.stringify(examType)}`;
    }
    if(grade?.length){
      url += `&grade=${JSON.stringify(grade)}`;
    }
    if (subject?.length) {
      url += `&subject=${JSON.stringify(subject)}`;
    }
    if (topic?.length) {
      url += `&topic=${JSON.stringify(topic)}`;
    }
    return this.http.get<CommonData>(url);
  }

  // create question
  createQuestionTagMapping(form: any): Observable<any> {
    return this.http.post<any>(`${this.API}/dashboard-v2/map-tags`, form);
  }

  deleteExamType(form: any): Observable<any> {
    return this.http.patch<any>(`${this.API}/dashboard-v2/delete-examtype`, form);
  }

  deleteGrade(form: any): Observable<any> {
    return this.http.patch<any>(`${this.API}/dashboard-v2/delete-grade`, form);
  }

  deleteSubject(form: any): Observable<any> {
    return this.http.patch<any>(`${this.API}/subject/delete`, form);
  }

  // create question
  deleteTopic(form: any): Observable<any> {
    return this.http.patch<any>(`${this.API}/topic/delete`, form);
  }

  getSubject(page?: number, search?: string, examType? :any, grade? : any): Observable<CommonData> {
    let url = `${this.API}/dashboard-v2/subject`;
    if (page) {
      url += `?page=${page}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (examType?.length) {
      if(page){
        url += `&exam_type=${JSON.stringify(examType)}`;
      }else{
        url += `?exam_type=${JSON.stringify(examType)}`;
      }
    }
    if(grade?.length){
      url += `&grade=${JSON.stringify(grade)}`;
    }
    return this.http.get<CommonData>(url);
  }

  getTopic(page?: number, search?: string, examType? :any, grade?: any, subject?: any): Observable<CommonData> {
    let url = `${this.API}/dashboard-v2/topic-dropdown`;
    if (page) {
      url += `?page=${page}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (examType?.length) {
      if(page){
        url += `&exam_type=${JSON.stringify(examType)}`;
      }else{
        url += `?exam_type=${JSON.stringify(examType)}`;
      }
    }
    if (grade?.length) {
      url += `&grade=${JSON.stringify(grade)}`;
    }
    if (subject?.length) {
      url += `&subject=${JSON.stringify(subject)}`;
    }
    return this.http.get<CommonData>(url);

  }

  getSingleLink(page?: number, search?: string): Observable<CommonData> {
    let url = `${this.API}/dashboard-v2/get-link`;
    if (page) {
      url += `?page=${page}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return this.http.get<CommonData>(url);
  }

  getBatchList(): Observable<CommonData> {
    return this.http.get<CommonData>(`${this.API}/dashboard-v2/batch-dropdown`);
  }

  addSingleLink(form: any): Observable<CommonData> {
    return this.http.post<any>(`${this.API}/dashboard-v2/add-link`, form);
  }

  updateSingleLink(id:number, form: any): Observable<CommonData> {
    return this.http.put<any>(`${this.API}/dashboard-v2/${id}/update-link`, form);
  }
  
  deleteSingleLink(form: any): Observable<any> {
    return this.http.patch<any>(`${this.API}/dashboard-v2/delete-link`, form);
  }

  getSingleLinkById(id:number): Observable<CommonData> {
    return this.http.get<CommonData>(`${this.API}/dashboard-v2/${id}/retrieve-link`);
  }

  
  // get reasoning type
  getReasoningType(): Observable<CommonData> {
    return this.http.get<CommonData>(`${this.API}/reasoning-type`);
  }

  // get academic standard
  getAcademicStandard(): Observable<any> {
    return this.http.get<any>(`${this.API}/academic-standard`);
  }

  // create question
  createQuestion(form: any): Observable<any> {
    return this.http.post<any>(`${this.API}/question`, form);
  }

  // update question
  updateQuestion(id: number, form: any): Observable<any> {
    return this.http.put<any>(`${this.API}/question/${id}`, form);
  }
  
  // delete files of question & options in update question page
  deleteFileInUpdateQuestion(
    id :any,
    image_type: string,
    image_id: number
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.API}/question/${id}/delete-image?image_type=${image_type}&image_id=${image_id}`
    );
  }

  // get by question id
  getQuestionById(id:string): Observable<any> {
    return this.http.get<any>(`${this.API}/question/${id}`);
  }

   /**
   *
   * @returns the list for the tag mapping
   */
   getQueTagMapping(): Observable<CommonData> {
    return this.http.get<CommonData>(`${this.API}/question/tag-mapping`);
  }

    /**
   * @param questionId question id
   * @returns the question tag list based on ther questions
   */
    getQueSelectedTags(questionId?: any): Observable<CommonData> {
      let url = `${this.API}/question/${questionId}/get-tags`;
      return this.http.get<CommonData>(url);
    }

    // delete question
  deleteQuestion(id: Object): Observable<any> {
    return this.http.delete<any>(`${this.API}/question/${id}`);
  }

   // delete multiple questions
   deleteMulQuestions(data: any): Observable<any> {
    return this.http.put<any>(`${this.API}/dashboard-v2-question-tag/delete`, data);
  }

  // get subscription billing cycle
  getSubscriptionBillingCycle(): Observable<CommonData> {
    let url = `${this.API}/subscription-billing-cycle`;
    return this.http.get<CommonData>(url);
  }
  
  // get subscription billing cycle details
  getSubscriptionBillingCycleDetails(id:string): Observable<CommonData> {
    let url = `${this.API}/subscription-billing-cycle/${id}`;
    return this.http.get<CommonData>(url);
  }
  
  // create subscription
  createSubscription(form: any): Observable<any> {
    return this.http.post<any>(`${this.API}/subscription`, form);
  }

  // get subscription list
  subscriptionList(page?: number, search?: string): Observable<any> {
    let url = `${this.API}/subscription`;
    if (page) {
      url += `?page=${page}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return this.http.get<CommonData>(url);
  }

   // get subscription byid
   getSubscriptionById(id:string): Observable<any> {
    return this.http.get<any>(`${this.API}/subscription/${id}`);
  }

  // update subscription byid
  updateSubscription(id:number, form: any): Observable<CommonData> {
    return this.http.patch<any>(`${this.API}/subscription/${id}`, form);
  }

  // update student subscription byid
  updateStudentSubscription(id:number, form: any): Observable<CommonData> {
    return this.http.patch<any>(`${this.API}/student-subscription/${id}`, form);
  }
   
  // create subscription billing cycle
  createSubscriptionBillingCycle(form: any): Observable<any> {
    return this.http.post<any>(`${this.API}/subscription-billing-cycle`, form);
  }

  // ipdate subscription billing cycle
  updateSubscriptionBillingCycle(id:string, form: any): Observable<any> {
  return this.http.patch<any>(`${this.API}/subscription-billing-cycle/${id}`, form);
}

// get subscription list
subscriptionHistory(page?: number, search?: string): Observable<any> {
  let url = `${this.API}/subscription-history`;
  if (page) {
    url += `?page=${page}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  return this.http.get<CommonData>(url);
}

 // get subscription byid
 getSubscriptionHistoryId(id:string): Observable<any> {
  return this.http.get<any>(`${this.API}/subscription-history/${id}`);
}

// create admin settings
 createAdminSettings(form: any): Observable<any> {
  return this.http.post<any>(`${this.API}/admin-setting`, form);
}

// update admin settings
  updateAdminSettings(id:string,form: any): Observable<any> {
    return this.http.patch<any>(`${this.API}/admin-setting/${id}`, form);
  }
    
  // get admin settings list
  getAdminSettings(page?: number, search?: string): Observable<CommonData> {
    let url = `${this.API}/admin-setting`;
    if (page) {
      url += `?page=${page}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return this.http.get<CommonData>(url);
  }

  // get admin settings list by id
  getAdminSettingsById(id:string): Observable<CommonData> {
    return this.http.get<CommonData>(`${this.API}/admin-setting/${id}`);
  }

   // update subscription history by id
   updateSubscriptionHistory(id:number, form: any): Observable<CommonData> {
    return this.http.patch<any>(`${this.API}/subscription-history/${id}`, form);
  }
}
